import React from 'react';
import NavLink from '../Components/NavLink';

function NotFound() {
  return (
    <div id="rev-NotFoundPage">
      <div className="logo-box">
        <img
          src="/images/site-logo.png"
          alt="Logo"
          style={{
            height: 40,
            objectFit: 'contain',
          }}
        />
      </div>
      <div className="window-container">
        <div className="header">
          <div />
          <div />
          <div />
        </div>
        <div className="content">
          <img src="/images/site-notfound.png" alt="not-found" />
          <h1>ERROR 404</h1>
          <h2>找不到網頁</h2>
          <p>此頁面不存在！歡迎繼續瀏覽本網站！</p>
          <NavLink to="/">
            <button>點我回首頁</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
